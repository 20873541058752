<template>
    <div>
        <template v-if="isMobile">
            <homeMobile></homeMobile>
        </template>
        <template v-else>
            <homePC></homePC>
        </template>
    </div>
</template>

<script>
import homePC from '@/components/homePC.vue';
import homeMobile from '@/components/homeMobile.vue';

export default {
    name: 'homeIndex',
    components: {
        homePC,
        homeMobile
    },
    data() {
        return {
            isMobile:false
        }
    },
    mounted() {
        this.handleResize()
         // 监听屏幕宽度和高度变化
         window.addEventListener('resize', this.handleResize, true)
    },
    methods: {
        handleResize() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
            console.log('123',this.isMobile)
        }
    }

}
</script>

<style></style>