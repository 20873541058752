
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
 
// 引入组件（方式一）
import Home from '../views/home/index.vue'
import downLoad from '../views/home/downLoad.vue'

 
// 配置路由
const routes = [
  {
    path: '/',
    name:'home',
    component: Home
  },
  {
	// 路径
    path: '/downLoad',
    name:'downLoad',
    component: downLoad
    // // 引入并使用组件（方式二）
    // component: () => import('.')
  }
]
 
const router = new VueRouter({
  routes
})
 
export default router
