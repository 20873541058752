<template>
    <div class="load">
        <img class="load-img" src="https://ossstatic.yidaxz.com/www/download.png" alt="" srcset="">
        <div class="load-btn" @click="checkMobile">立即下载</div>
        <div class="load-end">
            <img class="load-end-img" src="../../static/img/loadLogo.png" alt="" srcset="">
            <div>一搭数字人</div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            isMobile: false,
        };
    },
    created() {
        // this.checkMobile();
    },
    methods: {
        async downLoad() {
            this.$message({
                message: '加载中',
                center: true
            });
            try {
                const response = await axios({
                    url: 'https://ossstatic.yidaxz.com/yidadm-lastversion.apk',  // 替换为你的文件URL
                    method: 'GET',
                    responseType: 'blob', // 重要
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'yida.apk'); // 你可以根据需要更改文件名
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.error('下载文件时出错:', error);
            }

        },
        checkMobile() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            let isMobile = /android/i.test(userAgent);
            console.log('123', isMobile)
            //是否是微信浏览器
            if (/(micromessenger)/i.test(navigator.userAgent)) {
                if (isMobile) {
                    // //是否电脑微信或者微信开发者工具
                    // if (/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)) {
                    //     // alert('电脑微信或者微信开发者工具')
                    //     alert('请使用浏览器打开')
                    // } else {
                    //     //手机微信打开的浏览器
                    //     // alert('手机微信')
                    //     alert('请使用浏览器打开')
                    // }
                    alert('请使用浏览器打开')
                } else {
                    alert('暂不支持苹果端')
                }
            } else {
                // alert('其他浏览器')
                console.log('其他浏览器')
                this.downLoad()
            }
        },
    },

};
</script>

<style scoped>
.load {
    text-align: center;
}

.load-img {
    width: 23.4rem;
}

.load-end {
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-btn {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    background-color: #000000;
    border-radius: 20px;
    color: #fff;
    padding: 10px 0px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 10px;
}

.load-end {
    padding: 10px 0px;
    font-weight: bold;
}

.load-end-img {
    border-radius: 50%;
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 10px;
}
</style>