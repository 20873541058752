import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';//导入element-ui
import 'element-ui/lib/theme-chalk/index.css';//引入全部组件
import VueScrollTo from 'v-scroll-to'
import device from "vue-device-detector"
Vue.use(VueScrollTo);
Vue.use(ElementUI);
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });//设置全局配置对象

Vue.config.productionTip = false

new Vue({
  router,
  device,
  render: h => h(App),
}).$mount('#app')
