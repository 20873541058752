import { render, staticRenderFns } from "./homeMobile.vue?vue&type=template&id=8bad1b86"
import script from "./homeMobile.vue?vue&type=script&lang=js"
export * from "./homeMobile.vue?vue&type=script&lang=js"
import style0 from "./homeMobile.vue?vue&type=style&index=0&id=8bad1b86&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports